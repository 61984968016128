import *  as ActionTypes from "./cityActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    cities: [],
    citiesAuto: []
};

export const cityReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_CITY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_CITY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_CITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_CITY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_CITY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_CITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_CITY:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_CITY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_CITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                cities: action?.payload?.data || []
            }
        case ActionTypes.GET_CITY_AUTO:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_CITY_AUTO_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_CITY_AUTO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                citiesAuto: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_CITY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_CITY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_CITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}