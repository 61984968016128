export const CREATE_CITY = "CREATE_CITY";
export const CREATE_CITY_FAILURE = "CREATE_CITY_FAILURE";
export const CREATE_CITY_SUCCESS = "CREATE_CITY_SUCCESS";

export const DELETE_CITY = "DELETE_CITY";
export const DELETE_CITY_FAILURE = "DELETE_CITY_FAILURE";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";

export const GET_CITY = "GET_CITY";
export const GET_CITY_FAILURE = "GET_CITY_FAILURE";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";

export const GET_CITY_AUTO = "GET_CITY_AUTO";
export const GET_CITY_AUTO_FAILURE = "GET_CITY_AUTO_FAILURE";
export const GET_CITY_AUTO_SUCCESS = "GET_CITY_AUTO_SUCCESS";

export const UPDATE_CITY = "UPDATE_CITY";
export const UPDATE_CITY_FAILURE = "UPDATE_CITY_FAILURE";
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS";
